module.exports = [
  {
    "projID": "sittris",
    "className": "sittris",
    "projNUM": 1,
    "id": 200,
    "image": "../../feature-images-svg/1-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "roka",
    "className": "roka1",
    "projNUM": 2,
    "id": 201,
    "image": "../../feature-images-svg/2-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "xdclaymore",
    "className": "xdclaymore",
    "projNUM": 3,
    "id": 202,
    "image": "../../feature-images-svg/3-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "cuisipro",
    "className": "cuisipro1",
    "projNUM": 4,
    "id": 203,
    "image": "../../feature-images-svg/4-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "rideconcepts",
    "className": "rideconcepts1",
    "projNUM": 5,
    "id": 204,
    "image": "../../feature-images-svg/5-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "gourmet-settings",
    "className": "gourmet-settings",
    "projNUM": 6,
    "id": 205,
    "image": "../../feature-images-svg/6-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "revant",
    "className": "revant",
    "projNUM": 7,
    "id": 206,
    "image": "../../feature-images-svg/7-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "+1",
    "className": "case",
    "projNUM": 8,
    "id": 207,
    "image": "../../feature-images-svg/8-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "switch-vision",
    "className": "switch-vision",
    "projNUM": 9,
    "id": 208,
    "image": "../../feature-images-svg/9-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "bangerz",
    "className": "bangerz",
    "projNUM": 10,
    "id": 209,
    "image": "../../feature-images-svg/10-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "rideconcepts",
    "className": "rideconcepts2",
    "projNUM": 11,
    "id": 210,
    "image": "../../feature-images-svg/11-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "cuisipro",
    "className": "cuisipro2",
    "projNUM": 12,
    "id": 211,
    "image": "../../feature-images-svg/12-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "thermos",
    "className": "thermos",
    "projNUM": 13,
    "id": 212,
    "image": "../../feature-images-svg/13-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "revo",
    "className": "revo",
    "projNUM": 14,
    "id": 213,
    "image": "../../feature-images-svg/14-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "foldinglawnchair",
    "className": "foldinglawnchair",
    "projNUM": 15,
    "id": 214,
    "image": "../../feature-images-svg/15-light.svg",
    "alt": "need to add text here",
  },
  {
    "projID": "roka",
    "className": "roka2",
    "projNUM": 16,
    "id": 215,
    "image": "../../feature-images-svg/16-light.svg",
    "alt": "need to add text here",
  },
]

