import React from 'react';


export default class Footer extends React.Component {
    
    render() {
        return (
                <div className="footer__copyright">
                Site Design &amp; Development by Meera Shah | Copyright © 2020 Jason Clerk
                </div>
        );
    }
}
